import { ConnectButton as RKConnectButton } from '@rainbow-me/rainbowkit';
import { Button, ButtonProps } from '@onbeam/ui';
import { css } from '@onbeam/styled-system/css';
import { BeamNetworkIcon } from '@onbeam/icons';

export const ConnectButton = ({ size = 'sm' }: Pick<ButtonProps, 'size'>) => {
  return (
    <RKConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        if (!connected) {
          return (
            <Button onClick={openConnectModal} size={size} isLoading={!ready}>
              connect
            </Button>
          );
        }

        if (chain.unsupported) {
          return (
            <Button onClick={openChainModal} size={size}>
              wrong network
            </Button>
          );
        }

        return (
          <>
            <Button
              onClick={openChainModal}
              size={size}
              aria-label={chain.name?.toLowerCase()}
            >
              <BeamNetworkIcon height={16} width={16} />
              <span className={css({ hideBelow: '[58em]' })}>
                {chain.name?.toLowerCase()}
              </span>
            </Button>
            <Button onClick={openAccountModal} size={size} hasLed ledOn>
              <span className={css({ lineClamp: '1', hideBelow: '[23em]' })}>
                {account.ensName ?? account.displayName}
              </span>
            </Button>
          </>
        );
      }}
    </RKConnectButton.Custom>
  );
};
